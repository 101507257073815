import css from '@emotion/css';
import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';
import { ComingSoon } from '..';
import type { NycLocationsPageQuery } from '../../../../graphql-types';
import Layout from '../../../components/layout';
import CitiesMap from '../../../components/locations/CitiesMap';
import SEO from '../../../components/seo';
import { breadcrumbNewYorkCity } from '../../../data/seo';
import Heading1 from '../../../ui/Heading1';
import ParagraphSmall from '../../../ui/ParagraphSmall';
import { Uppercase } from '../../../ui/TypographyStyles';
import { BREAKPOINTS, COLORS } from '../../../ui/variables';
import { serializeLocation } from '../../../utils/location';
import {
  ArrowRight,
  Content,
  Heading,
  HeadingWrap,
  Left,
  LinkTo,
  Location,
  LocationList,
  LocationName,
  Right,
} from '../washington-dc';

export const pageQuery = graphql`
  query NYCLocationsPage {
    allContentfulPagesLocationTemplate(
      filter: {
        locationRegion: { eq: "new-york-city" }
        ghostLocation: { eq: false }
      }
      sort: { fields: isComingSoon }
    ) {
      nodes {
        ...LocationForMaps
      }
    }
  }
`;

const NYCLocationPage: React.FC<PageProps<NycLocationsPageQuery>> = ({
  data,
}) => {
  const locations = data.allContentfulPagesLocationTemplate.nodes.map(
    serializeLocation
  );

  const locationListWrap = (
    <LocationList>
      {locations.map(location => (
        <Location key={location.path}>
          <LinkTo to={location.path}>
            <div
              css={css`
                @media ${BREAKPOINTS.MOBILE} {
                  margin-top: ${location.isComingSoon ? '34px' : 'inherit'};
                }
              `}
            >
              <LocationName>{location.name}</LocationName>
              <ParagraphSmall
                css={css`
                  margin: 0;
                `}
              >
                {location.address}
              </ParagraphSmall>
            </div>
            <div
              css={css`
                height: fit-content;
              `}
            >
              {location.isComingSoon && (
                <ComingSoon
                  css={css`
                    margin-bottom: 0;
                    margin-right: 20px;
                    @media ${BREAKPOINTS.MOBILE} {
                      position: absolute;
                      top: 24px;
                      left: 0;
                    }
                  `}
                />
              )}
              <ArrowRight />
            </div>
          </LinkTo>
        </Location>
      ))}
    </LocationList>
  );

  return (
    <Layout>
      <SEO
        title='Modern New York City Vet Practices | Small Door Veterinary'
        description='Our state-of-the-art New York City veterinary practices are modern, stress-free environments, conveniently located near where you live, across NYC.'
        breadcrumbSchema={breadcrumbNewYorkCity}
      />
      <HeadingWrap>
        <Heading>
          <Uppercase
            css={css`
              color: ${COLORS.ORANGE};
              margin: 0 auto;
              width: fit-content;
            `}
          >
            <Link to='/locations'>Locations</Link>
          </Uppercase>
          <Heading1
            css={css`
              margin-top: 24px;
            `}
          >
            Our New York Veterinary Practices
          </Heading1>
        </Heading>
      </HeadingWrap>

      <Content>
        <Left>
          <div
            css={css`
              width: 100%;
            `}
          >
            {locationListWrap}
          </div>
        </Left>

        <Right>
          <CitiesMap id='desktop-map' locations={locations} />
        </Right>
      </Content>
    </Layout>
  );
};

export default NYCLocationPage;
